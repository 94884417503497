<template>
    <LiefengModal title="授权机构" :value="modalStatus" @input="modalStatusFn" :fullscreen="true">
        <template v-slot:contentarea>
            <div class="container">
                <div class="main">
                    <div class="search">
                        <Cascader style="width: 200px; display: inline-block" @on-change="changeCascader" placeholder="请选择市/区/街" :data="cascaderData" :load-data="loadCascaderData" v-model="cascader" change-on-select></Cascader>
                        <Input v-model="name" style="width: 200px; margin-left: 10px" placeholder="通过机构名称或编码查询"></Input>
                        <Button type="primary" style="margin-left: 10px" icon="ios-search" @click="search">查询</Button>
                        <Button type="success" style="margin-left: 10px" icon="ios-refresh" @click="reset">重置</Button>
                    </div>
                    <LiefengTable
                        ref="table"
                        :talbeColumns="tableColumns"
                        :curPage="page"
                        :total="total"
                        :tableData="tableData"
                        :height="tableHeight"
                        :loading="loading"
                        :pagesizeOpts="[50, 200, 500, 1500]"
                        :pageSize="pageSize"
                        @hadlePageSize="hadlePageSize"
                        @tableSelect="tableSelect"
                    ></LiefengTable>
                </div>
                <div class="button">
                    <Button type="info" @click="toRight">右移</Button>
                    <Button type="warning" @click="delRight">删除</Button>
                    <Button type="error" @click="clearRight">清空</Button>
                </div>
                <div class="right">
                    <div class="rightTitle">已选择</div>
                    <List border size="small">
                        <ListItem v-for="(item, index) in rightList" :key="item.orgCode">
                            <div style="width: 100%; display: block" @click="selectRightList($event, index)">
                                {{ item.orgName }}
                            </div>
                        </ListItem>
                    </List>
                </div>
            </div>
        </template>
        <template v-slot:toolsbar>
            <Button type="info" @click="cancel" style="margin-right: 20px">取消</Button>
            <Button type="primary" :loading="saveLoading" @click="save">确定</Button>
        </template>
    </LiefengModal>
</template>

<script>
//@route('/authorizeindex')
import LiefengModal from "@/components/LiefengModal"
import LiefengTable from "@/components/LiefengTable"

import proxy from "@/api/proxy"

export default {
    data() {
        return {
            tableHeight: "",
            modalStatus: false,
            cancelModalTip: false,
            orgList: [],
            orgCode: "",
            orgCodeName: "",

            tableColumns: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "机构名称",
                    key: "orgName",
                    minWidth: 90,
                    align: "center",
                },
                {
                    title: "机构编码",
                    key: "orgCode",
                    minWidth: 90,
                    align: "center",
                },
                {
                    title: "所属维度",
                    key: "orgCodeName",
                    minWidth: 90,
                    align: "center",
                },
            ],
            tableData: [],
            loading: false,
            pageSize: 50,
            total: 0,
            page: 1,

            // 级联
            cascaderData: [],
            cascader: [],
            // 搜索
            name: "",
            // 标识选择市，区或街
            index: "",

            tableSelectArr: [],
            rightList: [],
            removeRightList: [],

            // 确定按钮loading
            saveLoading: false,
        }
    },
    methods: {
        display(appId, orgLevel = 5) {
            // if (!this.isInitial) {
            //     this.onInitialData()
            // }
            this.orgLevel = orgLevel
            this.appId = appId
            this.modalStatus = true

            this.onInitialData()
        },

        onInitialData() {
            this.changeOrgCode()
            this.getCascaderData()
            this.isInitial = true
        },

        // 取消按钮
        cancel() {
            this.modalStatus = false
            this.rightList = []
        },

        // 保存按钮
        save() {
            if (this.rightList.length == 0) {
                this.$Message.error({
                    background: true,
                    content: "请选择需要授权的机构",
                })
                return
            }
            this.saveLoading = true
            try {
                const formdata = new FormData()

                this.rightList.map(item => {
                    formdata.append("orgCodeList", item.orgCode)
                })

                const xhr = new XMLHttpRequest()

                xhr.open("POST", `${proxy["/gateway"].target}/api/fastDev/AppForm/appAuthOrg/${this.appId}`)

                xhr.setRequestHeader("Authorization", window.sessionStorage.getItem("accessToken"))

                xhr.onloadstart = () => {
                    this.$Loading.start()
                }

                xhr.onloadend = () => {
                    this.$Loading.finish()
                }

                xhr.onload = () => {
                    this.saveLoading = false
                    if (xhr.status === 200) {
                        const res = JSON.parse(xhr.response)
                        if (res.code == 200) {
                            this.$Message.success("授权成功")
                            // 关闭弹窗
                            this.cancel()
                        } else this.$Message.error(res.desc)
                    }
                }

                xhr.send(formdata)
            } catch (err) {
                this.saveLoading = false
                this.$Message.error({
                    background: true,
                    content: "保存失败",
                })
            }
        },
        // 表格选中
        tableSelect(data) {
            this.tableSelectArr = JSON.parse(JSON.stringify(data))
        },
        // 获取是非示范社区
        async getonlineList(orgOnlineStatus) {
            await this.$get("/gx/pc/orgOnline/selectJWHByOnline", {
                orgOnlineStatus,
            }).then(res => {
                if (res.code == 200 && res.dataList.length) {
                    let arrId = []
                    this.rightList.map(item => {
                        arrId.push(item.orgCode)
                    })
                    let arrTable = []
                    res.dataList.map(item => {
                        if (arrId.indexOf(item.orgCode) == -1) {
                            arrTable.push(item)
                        }
                    })
                    this.rightList = this.rightList.concat(arrTable)
                } else {
                    this.$Message.warning({
                        background: true,
                        content: orgOnlineStatus ? "无示范社区" : "无非示范社区",
                    })
                }
            })
        },
        selectExample() {
            this.getonlineList(1)
        },
        selectUnExample() {
            this.getonlineList(0)
        },
        // 右移
        toRight() {
            let arrId = []
            this.rightList.map(item => {
                arrId.push(item.orgCode)
            })
            let arrTable = []
            this.tableSelectArr.map(item => {
                if (arrId.indexOf(item.orgCode) == -1) {
                    arrTable.push(item)
                }
            })
            this.rightList = this.rightList.concat(arrTable)
        },
        // 删除
        delRight() {
            if (this.removeRightList.length == 0) {
                this.$Message.error({
                    background: true,
                    content: "请从已选择列表中选中需要删除的机构",
                })
                return
            }
            let arr = []
            this.removeRightList.map(item => {
                arr.push(item.orgCode)
            })
            let arr2 = []
            this.rightList.map(item => {
                if (arr.indexOf(item.orgCode) == -1) {
                    arr2.push(item)
                }
            })
            this.rightList = arr2
            this.removeRightList = []
        },
        // 清空
        clearRight() {
            this.rightList = []
        },
        // 选中右侧
        selectRightList(e, index) {
            if (e.target.parentNode.classList.contains("active")) {
                e.target.parentNode.classList.remove("active")
                this.removeRightList.splice(index, 1)
            } else {
                e.target.parentNode.classList.add("active")
                this.removeRightList.push(this.rightList[index])
            }
        },
        //重置
        reset() {
            this.name = ""
            this.cascader = []
            this.index = ""
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
        // 查询
        search() {
            this.hadlePageSize({
                page: 1,
                pageSize: this.pageSize,
            })
        },
        // 机构维度改变
        changeOrgCode(item) {
            if (item) this.orgCodeName = item.label
            this.hadlePageSize(
                {
                    page: this.page,
                    pageSize: this.pageSize,
                },
                true
            )
        },
        // 获取表格数据
        hadlePageSize(obj, org) {
            //使用org标识来辨别是否是机构维度调用的
            this.loading = true
            this.$get("/gateway/org/pc/organization/selectOrganizationPage", {
                dimensionId: 1,
                orgName: org ? "" : this.name,
                searchOrgCode: org ? "" : this.cascader.length > 0 ? this.cascader[this.cascader.length - 1] : "",
                orgLevel: this.orgLevel,
                page: obj.page,
                pageSize: obj.pageSize,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        res.dataList.map((item, index) => {
                            item.index = index + 1
                        })
                        this.tableData = res.dataList.map(item => {
                            return {
                                ...item,
                                orgCodeName: this.orgCodeName,
                            }
                        })
                        this.total = res.maxCount
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.loading = false
                    } else {
                        this.loading = false
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.loading = false
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        changeCascader(val, data) {
            if (data.length > 0) {
                this.index = data[data.length - 1].index
            } else {
                this.index = ""
            }
        },
        // 动态获取市/区/街道
        loadCascaderData(item, callback) {
            item.loading = true
            this.$get("/gx/pc/organization/selectOrganizationByOrgCode", {
                orgCode: item.value,
            }).then(res => {
                if (res.code == 200 && res.dataList) {
                    if (item.index == 2) {
                        item.children = res.dataList.map(subItem => {
                            return {
                                value: subItem.orgCode,
                                label: subItem.orgName,
                                index: item.index + 1,
                            }
                        })
                    } else {
                        item.children = res.dataList.map(subItem => {
                            return {
                                value: subItem.orgCode,
                                label: subItem.orgName,
                                children: [],
                                loading: false,
                                index: item.index + 1,
                            }
                        })
                    }
                    item.loading = false
                    callback()
                } else {
                    item.loading = false
                    callback()
                    this.$Message.error({
                        background: true,
                        content: res.desc,
                    })
                }
            })
        },
        // 获取级联  市
        getCascaderData() {
            this.$get("/gx/pc/organization/selectOrganizationByOrgCode", {
                orgCode: "44",
            }).then(res => {
                if (res.code == 200 && res.dataList) {
                    this.cascaderData = res.dataList.map(item => {
                        return {
                            value: item.orgCode,
                            label: item.orgName,
                            children: [],
                            loading: false,
                            index: 1,
                        }
                    })
                } else {
                    this.$Message.error({
                        background: true,
                        content: res.desc,
                    })
                }
            })
        },
        modalStatusFn(status) {
            if (!status && this.cancelModalTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.modalStatus = status
                    },
                })
            } else {
                this.modalStatus = status
            }
        },
    },

    beforeMount() {
        this.tableHeight = window.innerHeight - 200 + ""
    },

    components: {
        LiefengModal,
        LiefengTable,
    },
}
</script>

<style scoped lang="less">
.container {
    display: flex;
    justify-content: space-around;
    height: calc(100vh - 123px);
    /deep/.left {
        margin: 0 10px;
        width: 200px;
        height: 100%;
        border-left: 1px solid #dcdee2;
        border-right: 1px solid #dcdee2;
        border-bottom: 1px solid #dcdee2;
        border-radius: 4px 4px 0 0;
        /deep/.ivu-select-dropdown {
            height: calc(100vh - 160px);
            max-height: calc(100vh - 160px);
        }
    }
    .main {
        // flex: 1;
        max-width: calc(100vw - 200px - 100px);
        min-width: 600px;
        .search {
            width: 100%;
            margin-bottom: 10px;
        }
        & > div {
            width: 100%;
        }
    }
    .button {
        width: 100px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .ivu-btn {
            margin-bottom: 16px;
        }
    }
    .right {
        width: 300px;
        height: 100%;
        border: 1px solid #dcdee2;
        overflow: auto;
        .rightTitle {
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            line-height: 40px;
            color: #515a6e;
            background: #f8f8f9;
        }
        /deep/.active {
            background: #19be6b;
            color: #fff;
        }
    }
}
/deep/.ivu-modal-close {
    display: none;
}
</style>
