<template>
    <div class="residentdatabase-my-business">
        <CompTable :title="type === 'DYTX' ? '数采服务系统' : '定制功能或报表'" :columns="columns" :table-api="tableApi">
            <template #custom>
                <Button v-if="type !== 'DYTX'" type="primary" icon="ios-add" @click="onJumpCreate">新增</Button>
            </template>
        </CompTable>
        <!-- 导入 -->
        <ImportsUpload ref="imports_upload" :type="type" :appId="appId"></ImportsUpload>
        <!-- 二维码 -->
        <CompQrCode ref="comp_qrcode"></CompQrCode>
        <!-- 发消息 -->
        <SendMessage ref="send_message" :communityCode="orgCode"></SendMessage>
        <!-- 查询条件 -->
        <QueryCriteria ref="query_criteria" :type="type"></QueryCriteria>
        <!-- 设置可见范围 -->
        <VisibleRange ref="visible_range"></VisibleRange>
        <!-- 授权 -->
        <Authorize ref="authorize"></Authorize>
        <!-- 导出用途（未完成） -->
        <CompModal ref="export_purpose_modal" title="导出用途" height="auto" :onSuccess="onSubmitExportApply" @on-close="export_purpose = ''">
            <Input v-model="export_purpose" type="textarea" placeholder="请填写导出用途" :rows="4" style="padding:20px"></Input>
        </CompModal>
    </div>
</template>

<script>
import CompModal from "./components/CompModal.vue"
import CompTable from "../jointly/components/comp-table.vue"
import CompQrCode from "./components/CompQrCode.vue"
import ImportsUpload from "./imports/upload.vue"
import SendMessage from "./children/SendMessage.vue"
import QueryCriteria from "./children/QueryCriteria.vue"
import VisibleRange from "./children/VisibleRange.vue"
import Authorize from "./children/Authorize.vue"

import proxy from "@/api/proxy"
import Route from "../communitymobilization/entity/Route"

export default {
    components: { CompModal, CompTable, CompQrCode, ImportsUpload, SendMessage, QueryCriteria, VisibleRange, Authorize },

    data() {
        const type = this.$core.getUrlParam("type")
        var orgCode

        if (type === "DYTX") {
            orgCode = parent.vue.loginInfo.userinfo.orgCode
        } else {
            orgCode = this.$core.getUrlParam("orgCode") || parent.vue.loginInfo.userinfo.orgCode
        }

        const ubr = parent.vue.userButtonRoot

        return {
            type: type,
            orgCode: orgCode,
            tableApi: `/gateway/api/fastDev/AppForm/findPageByOrgCode?orgCode=${orgCode}&orgLevel=4${type === "DYTX" ? "&busiCategory=DYTX" : ""}`,

            columns: [
                {
                    title: "功能名称",
                    key: "appName",
                    align: "center",
                },
                {
                    title: "描述",
                    key: "description",
                    maxWidth: 500,
                    align: "center",
                },
                ...(type === "DYTX"
                    ? []
                    : [
                          {
                              title: "常用设置",
                              minWidth: 200,
                              align: "center",
                              render: (h, params) => {
                                  return h(
                                      "Checkbox",
                                      {
                                          props: {
                                              value: !!params.row.customConfig?.indexQuick,
                                          },
                                          on: {
                                              "on-change": v => {
                                                  this.setCommon(params.row.appId, v)
                                              },
                                          },
                                      },
                                      "设为常用"
                                  )
                              },
                          },
                      ]),
                {
                    title: "可见范围",
                    key: "authScope",
                    minWidth: 130,
                    align: "center",
                },
                {
                    title: "最后更新",
                    key: "gmtModified",
                    minWidth: 150,
                    align: "center",
                },
                {
                    title: "状态",
                    key: "status",
                    minWidth: 120,
                    align: "center",
                },
                {
                    float: "right",
                    title: "操作",
                    width: type === "DYTX" ? 220 : 300,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            ...(type === "DYTX"
                                ? []
                                : [
                                      h(
                                          "Button",
                                          {
                                              props: {
                                                  type: "default",
                                                  size: "small",
                                              },
                                              style: "margin:0 3px",
                                              nativeOn: {
                                                  click: () => {
                                                      Route.jump(
                                                          `/residentdatabasedatatable?id=${params.row.appId}&title=${params.row.appName}&d=${params.row.status == "停用"}&orgCode=${this.orgCode}&s=report&type=${type}&gid=${params.row.customConfig?.chatGroupId || ""}&gn=${params.row.customConfig
                                                              ?.chatGroupName || ""}`
                                                      )
                                                  },
                                              },
                                          },
                                          "报表数据"
                                      ),
                                  ]),
                            ...(type !== "street"
                                ? [
                                      h(
                                          "Button",
                                          {
                                              props: {
                                                  type: type === "DYTX" ? "primary" : "default",
                                                  size: "small",
                                              },
                                              style: "margin:0 3px",
                                              nativeOn: {
                                                  click: () => {
                                                      Route.jump(
                                                          `/residentdatabasedatatable?id=${params.row.appId}&title=${params.row.appName}&d=${params.row.status == "停用"}&orgCode=${this.orgCode}&s=collect&type=${type}&ti=${params.row.templateId}&gid=${params.row.customConfig?.chatGroupId ||
                                                              ""}&gn=${params.row.customConfig?.chatGroupName || ""}`
                                                      )
                                                  },
                                              },
                                          },
                                          type === "DYTX" ? "明细" : "收集数据"
                                      ),
                                  ]
                                : []),
                            h(
                                "Dropdown",
                                {
                                    props: {
                                        transfer: true,
                                    },
                                    style: "margin:0 3px",
                                },
                                [
                                    h(
                                        "Button",
                                        {
                                            props: {
                                                type: "info",
                                                size: "small",
                                                icon: "ios-arrow-down",
                                            },
                                        },
                                        "更多"
                                    ),
                                    h(
                                        "DropdownMenu",
                                        {
                                            slot: "list",
                                        },
                                        [
                                            ...(params.row.status == "已启用"
                                                ? [
                                                      ...(type === "DYTX"
                                                          ? []
                                                          : [
                                                                h(
                                                                    "DropdownItem",
                                                                    {
                                                                        nativeOn: {
                                                                            click: () => {
                                                                                Route.jump(`/residentdatabasefixedbusiness?id=${params.row.appId}&orgCode=${this.orgCode}`)
                                                                            },
                                                                        },
                                                                        style: {
                                                                            textAlign: "center",
                                                                        },
                                                                    },
                                                                    "编辑"
                                                                ),
                                                                ...(~ubr.indexOf("RESIDENT:AUTHORIZE")
                                                                    ? [
                                                                          ...(type !== "street"
                                                                              ? [
                                                                                    h(
                                                                                        "DropdownItem",
                                                                                        {
                                                                                            nativeOn: {
                                                                                                click: () => {
                                                                                                    this.$refs.authorize.display(params.row.appId, 5)
                                                                                                },
                                                                                            },
                                                                                            style: {
                                                                                                textAlign: "center",
                                                                                            },
                                                                                        },
                                                                                        "授权给社区"
                                                                                    ),
                                                                                ]
                                                                              : []),
                                                                          h(
                                                                              "DropdownItem",
                                                                              {
                                                                                  nativeOn: {
                                                                                      click: () => {
                                                                                          this.$refs.authorize.display(params.row.appId, 4)
                                                                                      },
                                                                                  },
                                                                                  style: {
                                                                                      textAlign: "center",
                                                                                  },
                                                                              },
                                                                              "授权给街镇"
                                                                          ),
                                                                      ]
                                                                    : []),
                                                            ]),
                                                      h(
                                                          "DropdownItem",
                                                          {
                                                              nativeOn: {
                                                                  click: () => {
                                                                      this.$refs.query_criteria.display(params.row.appId)
                                                                  },
                                                              },
                                                              style: {
                                                                  textAlign: "center",
                                                              },
                                                          },
                                                          "设置查询条件"
                                                      ),
                                                      ~ubr.indexOf("RESIDENT:VISIBLE")
                                                          ? h(
                                                                "DropdownItem",
                                                                {
                                                                    nativeOn: {
                                                                        click: () => {
                                                                            this.$refs.visible_range.display(params.row.appId)
                                                                        },
                                                                    },
                                                                    style: {
                                                                        textAlign: "center",
                                                                    },
                                                                },
                                                                "设置可见范围"
                                                            )
                                                          : null,
                                                      type !== "street"
                                                          ? h(
                                                                "DropdownItem",
                                                                {
                                                                    nativeOn: {
                                                                        click: () => {
                                                                            this.appId = params.row.appId

                                                                            this.$refs.imports_upload.display()
                                                                        },
                                                                    },
                                                                    style: {
                                                                        textAlign: "center",
                                                                    },
                                                                },
                                                                "导入"
                                                            )
                                                          : null,
                                                      (type === "street"
                                                        ? ~ubr.indexOf("1002")
                                                        : true)
                                                          ? h(
                                                                "DropdownItem",
                                                                {
                                                                    nativeOn: {
                                                                        click: () => {
                                                                            this.onExport(params.row.appId)
                                                                        },
                                                                    },
                                                                    style: {
                                                                        textAlign: "center",
                                                                    },
                                                                },
                                                                "导出"
                                                            )
                                                          : h(
                                                                "DropdownItem",
                                                                {
                                                                    nativeOn: {
                                                                        click: () => {
                                                                            this.$refs.export_purpose_modal.display()
                                                                        },
                                                                    },
                                                                    style: {
                                                                        textAlign: "center",
                                                                    },
                                                                },
                                                                "申请导出"
                                                            ),
                                                      type !== "street"
                                                          ? h(
                                                                "DropdownItem",
                                                                {
                                                                    nativeOn: {
                                                                        click: () => {
                                                                            if (this.type === "DYTX") {
                                                                                this.$refs.comp_qrcode.display(`${proxy["/custom"].target}/#/apps/suiyue/pages/residentdatabase/form/form?ti=${params.row.templateId}&c=${this.orgCode}&type=DYTX`, params.row.appName, this.$core.getUrlParam("orgName"))
                                                                            } else {
                                                                                var url = `${proxy["/custom"].target}/#/pages/index/jump?oemCode=zjsm&id=${params.row.appId}&org_code=${this.orgCode}&type=${this.type || ""}&url=${
                                                                                    proxy["/custom"].target
                                                                                }/%23/apps/suiyue/pages/residentdatabase/form/form&currentOemCode=zjsm&orgCode=${this.orgCode}`

                                                                                this.$refs.comp_qrcode.display(url, params.row.appName, this.$core.getUrlParam("orgName"))
                                                                            }
                                                                        },
                                                                    },
                                                                    style: {
                                                                        textAlign: "center",
                                                                    },
                                                                },
                                                                "生成二维码"
                                                            )
                                                          : null,
                                                  ]
                                                : []),
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            if (params.row.status == "已启用") {
                                                                this.setDisable(params.row)
                                                            } else {
                                                                this.setOpenup(params.row)
                                                            }
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                params.row.status == "已启用" ? "禁用" : "启用"
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                        ])
                    },
                },
            ],

            appId: null,
            // 导出用途
            export_purpose: "",
        }
    },

    mounted() {
        // this.$refs.export_purpose_modal.display()
    },

    methods: {
        /**
         * 设置禁用
         */
        setDisable(v) {
            this.$post(`/gateway/api/fastDev/AppForm/stopApp/${v.appId}`).then(res => {
                if (res.code == 200) {
                    v.status = "停用"
                    this.$Message.success("禁用成功")
                } else {
                    this.$Message.error("禁用失败：" + res.desc)
                }
            })
        },

        /**
         * 设置/取消 常用功能
         */
        setCommon(appid, value) {
            this.$post(`/gateway/api/fastDev/AppForm/setIndexQuick/${appid}`, {
                quickFlag: value,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success((value ? "设置" : "取消") + "成功")
                } else {
                    this.$Message.error("设置失败：" + res.desc)
                }
            })
        },

        /**
         * 设置启用
         */
        setOpenup(v) {
            this.$post(`/gateway/api/fastDev/AppForm/issueApp/${v.appId}`).then(res => {
                if (res.code == 200) {
                    v.status = "已启用"
                    this.$Message.success("启用成功")
                } else {
                    this.$Message.error("启用失败：" + res.desc)
                }
            })
        },

        /**
         * 跳转到创建页面
         */
        onJumpCreate() {
            Route.jump(`/residentdatabasefixedbusiness?orgCode=${this.orgCode}`)
        },

        /**
         * 导出功能
         */
        onExport(appId) {
            this.$get(`/syaa/api/syuser/pc/userBaseIndex/exportReportExcel?communityCode=${this.orgCode}&appId=${appId}`).then(res => {
                if (res.code != 200) {
                    return this.$Message.error(res.desc)
                }

                // 创建一个 a 标签用于下载
                let e = document.createElement("a")

                e.href = res.data
                e.download = "数据导出_" + new Date().getTime()

                document.body.appendChild(e)
                e.click()
                document.body.removeChild(e)
            })
        },

        /**
         * 提交导出申请
         */
        onSubmitExportApply() {
            if (!this.export_purpose?.trim()) {
                return this.$Message.error("请填写导出用途")
            }
            // ...
        },
    },
}
</script>
